import { getFromStorage, setToStorage } from "@features/choose-dishes/api";
import { createEvent, createStore } from "effector";
import { useStore } from "effector-react";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";

const RUS_PHONE_REGEXP = /\d{11}$/;
export const isNumberValid = (value?: string | null) => RUS_PHONE_REGEXP.test(value as string);

const storageNumber = getFromStorage<string | null>("phone", false);

const onPhonChange = createEvent<string | null>();
export const $phone = createStore<string | null>(
  isNumberValid(storageNumber) ? storageNumber : null
).on(onPhonChange, (_, phone) => phone);

$phone.watch((phone) => {
  setToStorage("phone", phone);
});

const AddOrUpdateCheckoutContact: React.FC<any | null> = () => {
  const phone = useStore($phone);
  const [errorMessage, setErrorMessge] = useState<string | null>(null);
  const onBlur = () => {
    if (phone) setErrorMessge(isNumberValid(phone) ? null : "Номер введен не корректно")
  }
  const onChange = (phone: any) => {
    onPhonChange(phone);
    setErrorMessge(null);
  }
  return (
    <div className="pt-5 pb-5 sm:pt-5 sm:pb-5 bg-light rounded-xl flex flex-col justify-center min-h-0">
      <label
        htmlFor="phone"
        className="block text-body-dark font-semibold text-sm leading-none mb-3"
      >
          Телефон
      </label>
      <div className="flex flex-col sm:flex-row">

        <PhoneInput
          specialLabel={""}
          value={phone}
          placeholder="+7 925 12 34 56"
          inputProps={{name: "phone", onBlur : onBlur}}
          onChange={onChange}
          masks={["+9 (999) 999-99-99"]}
          inputClass="!p-0 !pe-4 !ps-4 !flex !items-end !w-full !appearance-none !transition !duration-300 !ease-in-out !text-body !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
          dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
        />
      </div>
      <div className="invalid-number-message text-sm text-red-600">{errorMessage}</div>
    </div>
  );
};

export default AddOrUpdateCheckoutContact;
