import { getFromStorage, setToStorage } from "@features/choose-dishes/api";
import { createEvent, createStore } from "effector";
import { useStore } from "effector-react";
import Input from './forms/input';

const storageName = getFromStorage("name", false);

const onNameChange = createEvent();
export const $name = createStore(
  storageName || null
).on(onNameChange, (_, event) => event.target.value);

$name.watch((name) => {
  setToStorage("name", name);
});

function NameInput(props) {
    const name = useStore($name);
    return (
        <div className="pt-5 pr-5 flex flex-col sm:flex-row">
            <Input
                name={"name"}
                variant="outline"
                label="Имя"
                value={name}
                onChange={onNameChange}
                placeholder="Имя"
            />
        </div>
    );
}

export default NameInput;