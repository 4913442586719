export const SwitchIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    fill="currentColor"
    version="1.1"
    {...props}
  >
    <g id="surface1">
      <path
        stroke="none"
        fillRule="nonzero"
        fill="currentColor"
        fillOpacity={1}
        d="M 35.402344 7.460938 L 35.402344 11.144531 C 35.136719 10.683594 34.855469 10.238281 34.546875 9.800781 C 33.476562 8.277344 32.175781 6.933594 30.6875 5.8125 C 29.183594 4.675781 27.515625 3.792969 25.738281 3.1875 C 23.894531 2.5625 21.96875 2.242188 20.007812 2.242188 C 17.960938 2.242188 15.957031 2.589844 14.042969 3.273438 C 12.195312 3.929688 10.476562 4.886719 8.9375 6.117188 C 7.414062 7.332031 6.109375 8.78125 5.054688 10.417969 C 3.984375 12.089844 3.203125 13.910156 2.742188 15.835938 C 2.445312 17.074219 3.207031 18.316406 4.441406 18.613281 C 4.621094 18.65625 4.804688 18.679688 4.980469 18.679688 C 6.023438 18.679688 6.964844 17.96875 7.21875 16.914062 C 7.898438 14.09375 9.527344 11.539062 11.8125 9.714844 C 12.953125 8.804688 14.222656 8.097656 15.589844 7.609375 C 17.003906 7.105469 18.492188 6.847656 20.007812 6.847656 C 22.890625 6.847656 25.625 7.761719 27.917969 9.492188 C 29.46875 10.660156 30.746094 12.171875 31.652344 13.878906 L 29.101562 13.878906 C 27.828125 13.878906 26.796875 14.910156 26.796875 16.183594 C 26.796875 17.453125 27.828125 18.484375 29.101562 18.484375 L 37.707031 18.484375 C 38.976562 18.484375 40.007812 17.453125 40.007812 16.183594 L 40.007812 7.460938 C 40.007812 6.1875 38.976562 5.15625 37.707031 5.15625 C 36.433594 5.15625 35.402344 6.1875 35.402344 7.460938 Z M 35.402344 7.460938 "
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill="currentColor"
        fillOpacity={1}
        d="M 2.300781 34.851562 C 3.574219 34.851562 4.605469 33.820312 4.605469 32.546875 L 4.605469 28.851562 C 5.976562 31.238281 7.886719 33.277344 10.203125 34.816406 C 13.109375 36.742188 16.496094 37.761719 19.992188 37.765625 C 19.996094 37.765625 19.996094 37.765625 20 37.765625 C 20 37.765625 20.003906 37.765625 20.003906 37.765625 C 20.003906 37.765625 20.007812 37.765625 20.007812 37.765625 C 20.011719 37.765625 20.015625 37.765625 20.015625 37.765625 C 22.058594 37.761719 24.058594 37.417969 25.964844 36.738281 C 27.8125 36.078125 29.53125 35.121094 31.070312 33.890625 C 32.59375 32.675781 33.902344 31.226562 34.953125 29.589844 C 36.027344 27.917969 36.804688 26.097656 37.265625 24.171875 C 37.5625 22.9375 36.804688 21.691406 35.566406 21.394531 C 34.332031 21.097656 33.085938 21.859375 32.789062 23.09375 C 32.109375 25.914062 30.480469 28.472656 28.199219 30.292969 C 27.058594 31.203125 25.785156 31.910156 24.417969 32.398438 C 23.003906 32.902344 21.519531 33.160156 20.003906 33.160156 C 17.410156 33.15625 14.902344 32.402344 12.75 30.976562 C 10.894531 29.746094 9.390625 28.082031 8.363281 26.128906 L 10.910156 26.128906 C 12.179688 26.128906 13.210938 25.097656 13.210938 23.824219 C 13.210938 22.554688 12.179688 21.523438 10.910156 21.523438 L 2.300781 21.523438 C 1.03125 21.523438 0 22.554688 0 23.824219 L 0 32.546875 C 0 33.820312 1.03125 34.851562 2.300781 34.851562 Z M 2.300781 34.851562 "
      />
    </g>
  </svg>
);
