import cover1 from "./cover-1.png";
import cover1_zip from "./cover-1.zip.png";
// import cover2 from "./cover-2.png";
// import cover2_zip from "./cover-2.zip.png";
// import cover3 from "./cover-3.png";
// import cover3_zip from "./cover-3.zip.png";
// import cover4 from "./cover-4.png";
// import cover4_zip from "./cover-4.zip.png";
// import cover5 from "./cover-5.png";
// import cover5_zip from "./cover-5.zip.png";

export const covers = [{ hugeSrc: cover1, src: cover1_zip }];
