export function WavesIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="149"
      height="57"
      viewBox="0 0 149 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-187 17.2299C-187 17.2299 -163.241 2 -135.218 2C-107.195 2 -100.19 17.2299 -73.3851 17.2299C-46.5805 17.2299 -35.615 2 -10.6379 2C14.3391 2 21.954 17.2299 45.7126 17.2299C69.4712 17.2299 82.8735 2 107.546 2C132.218 2 147.753 17.2299 147.753 17.2299"
        stroke="#11315F"
        stroke-width="2.39109"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-187 32.4596C-187 32.4596 -163.241 17.2297 -135.218 17.2297C-107.195 17.2297 -100.19 32.4596 -73.3851 32.4596C-46.5805 32.4596 -35.615 17.2297 -10.6379 17.2297C14.3391 17.2297 21.954 32.4596 45.7126 32.4596C69.4712 32.4596 82.8735 17.2297 107.546 17.2297C132.218 17.2297 147.753 32.4596 147.753 32.4596"
        stroke="#11315F"
        stroke-width="2.39109"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-187 47.6894C-187 47.6894 -163.241 32.4595 -135.218 32.4595C-107.195 32.4595 -100.19 47.6894 -73.3851 47.6894C-46.5805 47.6894 -35.615 32.4595 -10.6379 32.4595C14.3391 32.4595 21.954 47.6894 45.7126 47.6894C69.4712 47.6894 82.8735 32.4595 107.546 32.4595C132.218 32.4595 147.753 47.6894 147.753 47.6894"
        stroke="#11315F"
        stroke-width="2.39109"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-187 24.5401C-187 24.5401 -163.241 9.31024 -135.218 9.31024C-107.195 9.31024 -100.19 24.5401 -73.3851 24.5401C-46.5805 24.5401 -35.615 9.31024 -10.6379 9.31024C14.3391 9.31024 21.954 24.5401 45.7126 24.5401C69.4712 24.5401 82.8735 9.31024 107.546 9.31024C132.218 9.31024 147.753 24.5401 147.753 24.5401"
        stroke="#11315F"
        stroke-width="2.39109"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-187 39.77C-187 39.77 -163.241 24.5401 -135.218 24.5401C-107.195 24.5401 -100.19 39.77 -73.3851 39.77C-46.5805 39.77 -35.615 24.5401 -10.6379 24.5401C14.3391 24.5401 21.954 39.77 45.7126 39.77C69.4712 39.77 82.8735 24.5401 107.546 24.5401C132.218 24.5401 147.753 39.77 147.753 39.77"
        stroke="#11315F"
        stroke-width="2.39109"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-187 54.9998C-187 54.9998 -163.241 39.77 -135.218 39.77C-107.195 39.77 -100.19 54.9998 -73.3851 54.9998C-46.5805 54.9998 -35.615 39.77 -10.6379 39.77C14.3391 39.77 21.954 54.9998 45.7126 54.9998C69.4712 54.9998 82.8735 39.77 107.546 39.77C132.218 39.77 147.753 54.9998 147.753 54.9998"
        stroke="#11315F"
        stroke-width="2.39109"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
