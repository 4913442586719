import { AddressHeader } from "./address-header";
import { createEvent, createStore } from "effector";
import { getFromStorage, setToStorage } from "@features/choose-dishes/api";
import { useStore } from "effector-react";
import Button from "@shared/button";
import Input from "./forms/input";
import { $form, onSubmitForm } from "./address-form";
import './delivery-selector.css';

const typesOfDelivery = ["Доставка", "Самовывоз"];

export const isDelivery = (type) => {
    return (type ? type : getFromStorage("typeOfDelivery", false)) === typesOfDelivery[0];
}
const storageType = getFromStorage("typeOfDelivery", false);

const onTypeChange = createEvent();
export const $typeOfDelivery = createStore(
  storageType || typesOfDelivery[0]
).on(onTypeChange, (_, type) => type);

$typeOfDelivery.watch((type) => {
  setToStorage("typeOfDelivery", type);
});

export const DeliverySelector = () => {
    const typeOfDelivery = useStore($typeOfDelivery);
    const form = useStore($form);
    const onChangePersons = (e) => {
        const quantity = e.currentTarget.value;
        onSubmitForm({
            ...form,
            persons_number: quantity > 0 ? quantity : 1
        });
    }

  return (
    <div className="shadow-700 bg-light p-5 md:p-8">
      <AddressHeader label="Тип доставки" count={1} isEdit={false} />

      <div className="mt-5 md:mt-8 flex flex-wrap gap-5">
        {typesOfDelivery.map(type => {
            const selectedType = type === typeOfDelivery;
            return (
                <Button
                    onClick={() => onTypeChange(type)}
                    variant={selectedType ? "normal" : "outline"}
                    className={`${selectedType ? "text-body" : "text-white"} delivery-btn-cst border cursor-pointer hover:text-accent-hover w-44 lg:max-w-[270px] px-10`}
                >
                    {selectedType ? type : <div className="text-body text-button-cst">{type}</div>}
                </Button>
            );
        })}
      </div>
      <Input
        label={"Количество персон"}
        variant="outline"
        value={form ? form.persons_number : 0}
        onChange={onChangePersons}
        inputClassName="w-44"
        className="col-span-2 sm:col-span-1 pt-5"
        type="number"
      />
    </div>
  );
};
export default DeliverySelector;
