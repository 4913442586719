import ScheduleCard from "./schedule-card";
import { useEffect } from "react";
import { AddressHeader } from "./address-header";
import { createEvent, createStore } from "effector";
import { setToStorage } from "@features/choose-dishes/api";
import { useStore } from "effector-react";
import { $restaurant } from "@widgets/header/components/AddressSelection";
import { $typeOfDelivery, isDelivery } from "./delivery-selector";
import { Select } from "antd";
import "./schedule-grid.css";

type Schedule = {
  id: number;
  title: string;
  description: string;
};

interface ScheduleProps {
  label: string;
  className?: string;
  count: number;
}

const schedules = [
  {
    id: 0,
    title: "Экспресс доставка",
    description: "90 мин, ближайшая",
  },
  {
    id: 1,
    title: "10:00 - 13:00",
    description: "10:00 - 13:00",
  },
  {
    id: 2,
    title: "13:00 - 16:00",
    description: "13:00 - 16:00",
  },
  {
    id: 3,
    title: "16:00 - 19:00",
    description: "16:00 - 19:00",
  },
  {
    id: 4,
    title: "19:00 - 22:30",
    description: "19:00 - 22:30",
  },
];

const onScheduleChange = createEvent<Schedule | any>();
export const $schedule = createStore<Schedule | null>(null).on(
  onScheduleChange,
  (_, payload) => payload
);

$schedule.watch((state) => setToStorage("schedule", state));

const normalize = (v: number | string) => `${v}`.length > 1 ? `${v}`: `0${v}`;
const createTimeOption = (h: number | string, m: number | string) => normalize(h) + ":" + normalize(m);
export const ScheduleGrid: React.FC<ScheduleProps> = ({
  label,
  className,
  count,
}) => {
  const restaurant = useStore($restaurant);
  const typeOfDelivery = useStore($typeOfDelivery);

  const generateTimeOptions = () => {
    let until = 0;
    const since = 10;
    const offsetMinutes = 2;
    if (restaurant === "ул. Мясницкая, д. 11, м. Лубянка") until = 24;
    else until = 23;

    const opts:any = [];
    const now = new Date(new Date().getTime() + (1000*60*30));
    const nowH = now.getHours();
    const nowM = now.getMinutes();
    const addValue = (value:any) => opts.push({ value, label: value, title: value });

    if (nowH >= since && nowM <= (30 + offsetMinutes) && nowH < until) {
      if (nowM <= offsetMinutes) addValue(createTimeOption(nowH, 0));
      addValue(createTimeOption(nowH, 30));
    }
    if (now.getDate() === new Date().getDate()) {
      for (let i = nowH >= since ? nowH + 1 : since; i <= until; ++i) {
        addValue(createTimeOption(i, 0));
        if (i !== until) addValue(createTimeOption(i, 30));
      }
    }
    return opts;
  }
  const options = generateTimeOptions();

  const selectedSchedule = useStore($schedule);

  useEffect(() => {
      if (isDelivery(typeOfDelivery)) onScheduleChange(schedules[0]);
      else onScheduleChange(options[0]);
  }, [typeOfDelivery]);

  const onSelect = (v: any) => onScheduleChange({ id: 1, title: v, description: v});

  return (
    <div className={className}>
      <AddressHeader label={label} count={count} isEdit={false} />

      <div className="mt-5 md:mt-8">
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {isDelivery(typeOfDelivery) ? (schedules && schedules?.length ? (
            schedules?.map((schedule) => (
              <ScheduleCard
                key={schedule.id}
                checked={schedule.id === selectedSchedule?.id}
                schedule={schedule}
                onClick={() => onScheduleChange(schedule)}
              />
            ))
          ) : (
            <span className="relative px-5 py-6 text-base text-center bg-gray-100 rounded border border-border-200">
              {"Нет доступных расписаний"}
            </span>
          ))
            : (options.length > 0
              ? <div className="pb-5 sm:pb-5 rounded-xl flex flex-col justify-center min-h-0">
                <label className="block text-body-dark font-semibold text-sm leading-none mb-3">Заберу заказ в</label>
                <Select
                  size="large"
                  value={selectedSchedule?.title}
                  onChange={onSelect}
                  options={options}
                />
              </div>
              : <span className="relative px-5 py-6 text-base text-center bg-gray-100 rounded border border-border-200">
                {"Нет доступных расписаний"}
              </span>
            )
        }
        </div>
      </div>
    </div>
  );
};
export default ScheduleGrid;
