import { AddressHeader } from "./address-header";
import { useStore } from "effector-react";
import { $form, onSubmitForm } from "./address-form";
import TextArea from "@shared/components/text-area";


export const CommentInput = () => {
    const form = useStore($form);
    const onChangeComment = (e) => {

        const comment = e.currentTarget.value;
        onSubmitForm({
            ...form,
            comment
        });
    }

  return (
    <div className="shadow-700 bg-light p-5 md:p-8">
      <AddressHeader label="Комментарий к заказу" count={5} isEdit={false} />
      <TextArea
          onChange={onChangeComment}
          value={form ? form.comment : ""}
          variant="outline"
          className="col-span-4 mt-5 md:mt-8"
      />
    </div>
  );
};
export default CommentInput;
