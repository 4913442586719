import { AddressHeader } from "./address-header";
import { useStore } from "effector-react";
import { $restaurant } from "@widgets/header/components/AddressSelection";

export const PickUpBlock = () => {
    const restaurant = useStore($restaurant);
    return (
        <div className="shadow-700 bg-light p-5 md:p-8">
            <AddressHeader label="Ближайший ресторан" count={2} isEdit={false} />
            <div className="mt-5 md:mt-8 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                <div className=" relative py-5 px-4 rounded-xl border group text-body border-current shadow-sm">
                    <p className="mb-3 capitalize text-base text-body font-bold">
                        {"Адрес"}
                    </p>
                    <p className="text-sm text-muted">{restaurant}</p>
                </div>
            </div>
        </div>
    );
};
export default PickUpBlock;