import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const minute = 1000*60;
function TimeLeft({ start, allottedMinutes }) {
    const getMinutes = (time) => {
        const minutes = Math.ceil(((time.toDate().getTime() + (minute*(allottedMinutes || 90))) - dayjs())/minute);
        return minutes > 0 ? minutes : 0;
    }

    const [minuteLeft, setMinuteLeft] = useState(getMinutes(start || dayjs()));

    useEffect(() => {
        if (start) {
            const interval = setInterval(() => {
                setMinuteLeft(getMinutes(start));
            }, 2000);
            return () => {
                clearInterval(interval);
            }
        }
    }, [start]);

    return (
        <div className="text-2xl font-bold">
            {minuteLeft+" мин"}
        </div>
    );
}

export default TimeLeft;