export function PresentIcon({
  containerClassName,
  iconClassName,
}: {
  containerClassName?: string;
  iconClassName?: string;
}) {
  return (
    <div className={containerClassName}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 1.83441C7.758 -0.427029 4.12 -0.726323 2.577 1.66152C2.24662 2.16626 2.06412 2.74257 2.04763 3.33318C2.03114 3.92379 2.18124 4.50813 2.483 5.02812C1.78742 5.14123 1.15705 5.47878 0.70287 5.98136C0.248685 6.48393 -0.000207926 7.11932 1.30333e-07 7.77567V15.2115C1.30333e-07 15.9511 0.316071 16.6603 0.87868 17.1833C1.44129 17.7062 2.20435 18 3 18H15C15.7956 18 16.5587 17.7062 17.1213 17.1833C17.6839 16.6603 18 15.9511 18 15.2115V7.77567C18.0002 7.11932 17.7513 6.48393 17.2971 5.98136C16.8429 5.47878 16.2126 5.14123 15.517 5.02812C15.8188 4.50813 15.9689 3.92379 15.9524 3.33318C15.9359 2.74257 15.7534 2.16626 15.423 1.66152C13.88 -0.726323 10.242 -0.427029 9 1.83441ZM7.782 4.98536L7.379 3.11524C7.078 1.71636 5.082 1.39569 4.292 2.61796C3.62 3.65898 4.425 4.98164 5.73 4.9835L7.782 4.98536ZM8 6.84619H3C2.73478 6.84619 2.48043 6.94411 2.29289 7.11843C2.10536 7.29274 2 7.52916 2 7.77567V10.5641H8V6.84619ZM8 12.4231H2V15.2115C2 15.4581 2.10536 15.6945 2.29289 15.8688C2.48043 16.0431 2.73478 16.141 3 16.141H8V12.4231ZM10 16.141V12.4231H16V15.2115C16 15.4581 15.8946 15.6945 15.7071 15.8688C15.5196 16.0431 15.2652 16.141 15 16.141H10ZM16 10.5641H10V6.84619H15C15.2652 6.84619 15.5196 6.94411 15.7071 7.11843C15.8946 7.29274 16 7.52916 16 7.77567V10.5641ZM10.218 4.98536L12.27 4.9835C13.575 4.98164 14.38 3.65806 13.708 2.61796C12.918 1.39569 10.922 1.71636 10.621 3.11524L10.218 4.98536Z"
          className={iconClassName}
          fill="#130F26"
        />
      </svg>
    </div>
  );
}
