import { Timeline } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { getOrderStatus } from '@shared/api/common';
import Spinner from '@shared/components/spinner/spinner';
import "./orderStatus.css";

const checked = (<div className="time-checked"><div className="done"></div></div>);
const customDot = (<div className="custom-dot"></div>);

function OrderStatus({ orderNumber }) {
    const [statusData, setStatusData] = useState();
    const [error, setError] = useState();
    const timeoutRef = useRef();

    useEffect(() => {
        getStatus();
    }, []);

    const getStatus = () => {
        clearTimeout(timeoutRef.current);
        getOrderStatus(orderNumber).then(data => {
            setStatusData({ ...data });
            setError(null);
            timeoutRef.current = setTimeout(getStatus, 1000);
        }).catch(error => {
            setError("Невозможно получить статус заказа");
        });
    }
    let content = null;
    if (error) {
        content = (
            <div className="pt-8 ">
                <span className="text-red-500 pr-8">{error}</span>
                <button onClick={getStatus} className="w-full sm:w-auto text-body text-sm py-3 px-8 border border-current rounded-xl hover:bg-accent hover:text-light">Обновить</button>
            </div>
        );
    } else if (statusData) {
        content = (
            <Timeline className="pt-16 custom-timeline">
                {statusData.statuses.map((status, i) => {
                    return (
                        <Timeline.Item key={status} dot={i <= statusData.completedStatus ? checked : customDot}>
                            <p>{status}</p>
                        </Timeline.Item>
                    );
                })}
            </Timeline>
        );
    } else content = (<Spinner className="pt-8" text="Загрузка статуса" />);

    return (<div>{content}</div>);
}

export default OrderStatus;