export const MinusIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" {...props}>
    <path
      className="remove_from_cart"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20 12H4"
    />
  </svg>
);
